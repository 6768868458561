<template>
  <div>
    <Heard />

    <div class="container-fluid searchbox">
      <div class="conth container">
        <el-input
          v-model="searchname"
          style="width: 600px"
          clearable
          placeholder="请输入岗位或企业名称"
        ></el-input>
        <div
          class="smbox"
          @click="$router.push(`/post?searchname=${searchname}`)"
        >
          搜索
        </div>
      </div>
    </div>

    <div
      class="swiperbox container"
      v-if="complist"
      @mouseleave="(hoverdiv = false), (actpindex = '')"
    >
      <div class="left">
        <p
          v-for="(item, index) in complist"
          :key="item.jobType"
          @mouseenter="hover(item, index)"
          :class="{ actp: index === actpindex - 1 }"
        >
          {{ item.jobType }} <i class="el-icon-arrow-right"></i>
        </p>
        <div class="all" @click="$router.push('/post')">全部职位类型</div>
      </div>
      <div class="hoverdiv111" v-show="hoverdiv" v-if="actpindex">
        <div
          class="allpostitem"
          v-for="(child, index) in complist[actpindex - 1].child"
          :key="index"
          @click="$router.push(`/post?searchname=${child}`)"
        >
          {{ child }}
        </div>
      </div>
      <div class="swiper-container">
        <el-carousel height="390px">
          <el-carousel-item v-for="item in bannerlist" :key="item.id">
            <img :src="item.src" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="comtit container">
      <span> 最新岗位 </span>
    </div>
    <div class="post container">
      <div
        class="postitem"
        v-for="compitem in postlist"
        :key="compitem.id"
        @click="$router.push(`/postdeil/${compitem.id}.html`)"
      >
        <div class="topcc">
          <div class="top_desc">
            <p class="title">{{ compitem.jobName }}</p>
            <p class="time">{{ compitem.createTime.split(" ")[0] }}</p>
          </div>
          <div class="pricebox">
            <p class="money">{{ compitem.umoney }}</p>
            <p class="exp">{{ compitem.exp }}/{{ compitem.edu }}</p>
          </div>
        </div>
        <div class="bot">
          <div class="compinfo">
            <img
              :src="
                compitem.logoImgPath
                  ? compitem.logoImgPath
                  : require('@/assets/image/baseicon.png')
              "
              alt=""
            />
            <div class="compname">
              <p class="name">{{ compitem.companyName }}</p>
              <p class="adress">
                {{ compitem.address }}丨{{ compitem.jobType }}
              </p>
            </div>
            <div class="btn">投简历</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnbox container">
      <div @click="$router.push('/post')">查看更多</div>
    </div>
    <div class="container">
      <img
        class="container indexpic"
        src="@/assets/image/post_index.png"
        alt=""
      />
    </div>
    <Footer />
  </div>
</template>

<script >
import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
import { api_queryJobList, api_queryJobType } from "@/apis/apis.js";

export default {
  components: {
    Heard,
    Footer,
  },

  // head() {
  //   return {
  //     title: `面试辅导流程_面试技巧方法_面试辅导哪家好_职业云联面试辅导`,
  //     meta: [
  //       {
  //         hid: "description",
  //         name: "description",
  //         content:
  //           "职业云联平台是由原500强企业人力VP陶然先生协同部分人力专家团队，通过近三年自主开发、梳理联合各优质供应商和求职渠道，开发了大量面试辅导提升课程和在线求职辅导服务，同时整合上百家知名企业工作内部推荐渠道，打造出的职场类产品。",
  //       },
  //       {
  //         hid: "keywords",
  //         name: "keywords",
  //         content: "面试辅导，求职辅导，面试技巧，面试攻略",
  //       },
  //     ],
  //   };
  // },
  data() {
    return {
      searchname: "",

      postlist: [],
      complist: [],
      hoverdiv: false,
      actpindex: "",
      bannerlist: [
        { id: 0, src: require("@/assets/image/postb1.png") },
        { id: 1, src: require("@/assets/image/postb2.png") },
      ],
    };
  },

  created() {
    console.log(this.actpindex);
    this.getpostlist();
    this.morepost();
  },
  mounted() {},

  methods: {
    getpostlist() {
      api_queryJobList({
        type: 1, //1:岗位列表 2 企业列表
        currPage: 1,
        pageSize: 9,
      }).then((res) => {
        console.log(res);
        this.postlist = res.data.data.dataList;
      });
    },
    morepost() {
      api_queryJobType({}).then((res) => {
        this.complist = res.data.data.slice(0, 8);
      });
    },
    hover(item, index) {
      console.log(index);
      this.hoverdiv = true;
      this.actpindex = index + 1;
    },
  },
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
}
/* 版心 */
.container {
  width: 1200px;
  margin: 0 auto;
}
.all {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #458dff;
  margin-top: 20px;
}
.banner {
  height: 302px;
  width: 100%;
  background: url(~@/assets/image/banner3.png) no-repeat center;
}
.searchbox {
  height: 200px;
  display: flex;
  align-items: center;
  background-color: #458dff;
  .conth {
    display: flex;
    align-items: center;
    justify-content: center;
    .smbox {
      margin-left: 10px;
      color: #fff;
      border: 1px solid #fff;
      width: 100px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      font-size: 18px;
    }
  }
}
.swiperbox {
  display: flex;
  margin-top: 40px;
  position: relative;
  .hoverdiv111 {
    width: 750px;
    height: 395px;
    background-color: #fff;
    position: absolute;
    left: 313px;
    z-index: 2000;
    color: #000;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    align-content: flex-start;
    border-left: 1px solid #458dff;
    border-bottom: 1px solid #458dff;
    .allpostitem {
      margin-right: 24px;
      margin-bottom: 18px;
      height: 20px;
      color: #aaa;
      cursor: pointer;
    }
  }
  .left {
    flex: 1;
    z-index: 2200;
    width: 310px;

    p {
      padding: 0 14px;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      z-index: 2200;
      align-items: center;
    }
    .actp {
      border-bottom: 1px solid #458dff;
      border-top: 1px solid #458dff;
      color: #458dff;
      background-color: #fff;

      z-index: 2200;
    }
  }
  .swiper-container {
    width: 846px;
    height: 395px;
    margin-left: 40px;
    img {
      width: 846px;
      height: 395px;
    }

    .swiper-wrapper {
    }
    .swiper-slide {
    }
  }
}
.comtit {
  height: 48px;
  background: #fafafa;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  span {
    display: block;
    width: 150px;
    height: 48px;
    background: #ffffff;
    border-top: 4px solid #458dff;
    line-height: 42px;
    font-size: 18px;
    text-align: center;
  }
}
.post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .postitem {
    width: 360px;
    height: 182px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    padding: 18px;
    margin-bottom: 28px;
    .topcc {
      .top_desc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .title {
          font-size: 18px;
          font-weight: bold;
        }
        .time {
          font-size: 14px;
          color: #979fa5;
        }
      }
      .pricebox {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .money {
          font-size: 22px;
          font-weight: bold;
          color: #dc2e37;
          margin-right: 30px;
        }
        .exp {
          font-size: 14px;
        }
      }
    }
    .bot {
      border-top: 1px solid #ccc;
      padding-top: 10px;
      .compinfo {
        display: flex;
        img {
          width: 44px;
          height: 44px;
          border-radius: 6px;
          margin-right: 12px;
        }
        .compname {
          flex: 1;
          .name {
            font-size: 14px;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 180px;
          }
          .adress {
            font-size: 12px;
            color: #979fa5;
            margin-top: 9px;
          }
        }
        .btn {
          width: 70px;
          height: 30px;
          background: #2a84e0;
          border-radius: 4px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          line-height: 30px;
        }
      }
    }
  }
}
.btnbox {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  div {
    width: 200px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #2a84e0;
    font-size: 16px;
    color: #2a84e0;
  }
}
.indexpic {
  height: 737px;
}
</style>